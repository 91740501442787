import React, { useState, useEffect } from 'react';
import { ArrowRight, Cloud, Shield, Zap, Menu, X } from 'lucide-react';
import axios from 'axios';

// Definindo o componente Modal
const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white rounded-lg p-8 shadow-lg max-w-md mx-auto">
        {children}
        <button
          onClick={onClose}
          className="mt-4 bg-highlight hover:bg-primary text-secondary py-2 px-4 rounded-lg"
        >
          Fechar
        </button>
      </div>
    </div>
  );
};

const NavBar = ({ navItems, scrollY, isMenuOpen, setIsMenuOpen }) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => setIsMobile(window.innerWidth < 768);
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleNavItemClick = () => {
    if (isMobile) {
      setIsMenuOpen(false);
    }
  };

  const showLogo = isMobile ? scrollY > 50 : true;
  const showNavItems = isMobile ? (scrollY <= 50 && !isMenuOpen) : true;
  const showMenuIcon = isMobile && scrollY > 50;

  return (
    <header className={`fixed w-full z-50 transition-all duration-300 ${scrollY > 50 ? 'bg-primary shadow-lg' : 'bg-transparent'}`}>
      <div className="relative w-full flex items-center justify-between py-4 px-6 min-h-[60px]">
        {showMenuIcon && (
          <div className="md:hidden">
            {isMenuOpen ? (
              <X onClick={toggleMenu} className="text-secondary cursor-pointer" />
            ) : (
              <Menu onClick={toggleMenu} className="text-secondary cursor-pointer" />
            )}
          </div>
        )}
        {showLogo && (
          <div className="absolute left-1/2 transform -translate-x-1/2">
            <img
              src={scrollY > 50 ? "/images/logo-light.png" : "/images/logo-dark.png"}
              alt="Kadima Cloud Logo"
              className="h-12"
            />
          </div>
        )}
        {showNavItems && (
          <nav className={`${isMobile ? 'flex justify-center w-full' : 'hidden md:flex space-x-6 ml-auto'}`}>
            {navItems.map((item) => (
              <a
                key={item}
                href={`#${item.toLowerCase()}`}
                className={`
                  ${scrollY > 50 ? 'text-secondary' : 'text-primary'}
                  hover:text-accent transition-colors
                  ${isMobile ? 'mx-2' : ''}
                `}
                aria-label={`Ir para ${item}`}
                onClick={handleNavItemClick}
              >
                {item}
              </a>
            ))}
          </nav>
        )}
      </div>
      {isMenuOpen && isMobile && (
        <div className="bg-primary">
          <div className="container mx-auto px-4 py-4">
            {navItems.map((item, index) => (
              <a
                key={item}
                href={`#${item.toLowerCase()}`}
                className="block py-2 text-secondary hover:text-accent transition-colors"
                aria-label={`Ir para ${item}`}
                onClick={handleNavItemClick}
              >
                {`${index + 1}. ${item}`}
              </a>
            ))}
          </div>
        </div>
      )}
    </header>
  );
};

const App = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [scrollY, setScrollY] = useState(0);
  const [formData, setFormData] = useState({ name: '', email: '', message: '' });
  const [errors, setErrors] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false); // Controle do modal

  useEffect(() => {
    const handleScroll = () => setScrollY(window.scrollY);
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const navItems = ['Início', 'Serviços', 'Sobre', 'Contato'];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = {};

    if (!formData.name) newErrors.name = 'Nome é obrigatório';
    if (!formData.email) newErrors.email = 'Email é obrigatório';
    if (!formData.message) newErrors.message = 'Mensagem é obrigatória';

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (formData.email && !emailPattern.test(formData.email)) {
      newErrors.email = 'Email inválido';
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      try {
        await axios.post('https://api.github.com/graphql', {
          query: `
            mutation {
              addProjectV2DraftIssue(input: {
                projectId: "${process.env.REACT_APP_GITHUB_PROJECT_ID}",
                title: "Novo Lead Recebido de ${formData.name} (${formData.email})",
                body: "${formData.message}"
              }) {
                projectItem {
                  id
                }
              }
            }
          `
        }, {
          headers: {
            "Authorization": `Bearer ${process.env.REACT_APP_GITHUB_TOKEN}`,
            "Content-Type": "application/json",
          },
        });

        // Mostrando modal ao invés de alert
        setIsModalOpen(true);

        // Resetando o formulário
        setFormData({ name: '', email: '', message: '' });
        setErrors({});
      } catch (error) {
        console.error('Erro ao enviar a mensagem:', error);
        alert('Erro ao enviar a mensagem');
      }
    }
  };

  return (
    <div className="min-h-screen flex flex-col">
      <NavBar navItems={navItems} scrollY={scrollY} isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />

      <main className="flex-grow">
        <section id="início" className="scroll-section min-h-screen flex items-center bg-secondary">
          <div className="container mx-auto px-4 text-center">
            <img src="/images/kadima-cloud-dark.png" alt="Kadima Cloud Logo" className="h-40 mx-auto mb-6" />
            <p className="text-xl md:text-2xl mb-8 text-primary">
              Soluções em nuvem inovadoras para impulsionar seu negócio
            </p>
            <a href="#serviços" className="bg-highlight hover:bg-primary text-secondary py-3 px-6 rounded-full inline-flex items-center transition-colors">
              Explore Nossos Serviços <ArrowRight className="ml-2" />
            </a>
          </div>
        </section>

        <section id="serviços" className="scroll-section min-h-screen flex items-center bg-accent">
          <div className="container mx-auto px-4">
            <h2 className="text-4xl md:text-5xl font-bold mb-12 text-center text-primary">Nossos Serviços em Nuvem</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {[
                { name: 'Computação em Nuvem', icon: Cloud, description: 'Potencialize sua infraestrutura com nossas soluções de computação em nuvem de alto desempenho.' },
                { name: 'Segurança de Dados', icon: Shield, description: 'Proteja seus dados críticos com nossa avançada tecnologia de segurança em nuvem.' },
                { name: 'Alta Performance', icon: Zap, description: 'Alcance o máximo desempenho com nossa infraestrutura otimizada e escalável.' }
              ].map((service) => (
                <div key={service.name} className="bg-secondary p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow">
                  <service.icon className="w-12 h-12 mb-4 text-highlight" />
                  <h3 className="text-2xl font-semibold mb-4 text-primary">{service.name}</h3>
                  <p className="text-primary">{service.description}</p>
                </div>
              ))}
            </div>
          </div>
        </section>

        <section id="sobre" className="scroll-section min-h-screen flex items-center bg-primary text-secondary">
          <div className="container mx-auto px-4">
            <h2 className="text-4xl md:text-5xl font-bold mb-12 text-center">Sobre a Kadima Cloud</h2>
            <p className="text-xl md:text-2xl text-center">
              Somos uma empresa dedicada a trazer soluções tecnológicas de ponta para o seu negócio, com foco em inovação, segurança e alta performance.
            </p>
          </div>
        </section>

        <section id="contato" className="scroll-section min-h-screen flex items-center bg-secondary">
          <div className="container mx-auto px-4">
            <h2 className="text-4xl md:text-5xl font-bold mb-12 text-center text-primary">Fale Conosco</h2>
            <form onSubmit={handleSubmit} className="max-w-md mx-auto">
              <div className="mb-6">
                <label htmlFor="name" className="block text-primary mb-2">Nome</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className={`w-full p-4 border rounded-lg ${errors.name ? 'border-red-500' : 'border-gray-300'}`}
                  placeholder="Seu nome"
                />
                {errors.name && <p className="text-red-500 mt-2">{errors.name}</p>}
              </div>
              <div className="mb-6">
                <label htmlFor="email" className="block text-primary mb-2">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className={`w-full p-4 border rounded-lg ${errors.email ? 'border-red-500' : 'border-gray-300'}`}
                  placeholder="Seu email"
                />
                {errors.email && <p className="text-red-500 mt-2">{errors.email}</p>}
              </div>
              <div className="mb-6">
                <label htmlFor="message" className="block text-primary mb-2">Mensagem</label>
                <textarea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  className={`w-full p-4 border rounded-lg ${errors.message ? 'border-red-500' : 'border-gray-300'}`}
                  rows="5"
                  placeholder="Sua mensagem"
                />
                {errors.message && <p className="text-red-500 mt-2">{errors.message}</p>}
              </div>
              <button type="submit" className="w-full bg-highlight hover:bg-primary text-secondary py-4 rounded-lg transition-colors">
                Enviar Mensagem
              </button>
            </form>
          </div>
        </section>
      </main>

      <footer className="bg-primary py-6">
        <div className="container mx-auto text-center text-secondary">
          &copy; 2024 Kadima Cloud. Todos os direitos reservados.
        </div>
      </footer>

      {/* Modal de agradecimento */}
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <h2 className="text-2xl font-bold text-primary">Mensagem Enviada!</h2>
        <p className="mt-2 text-primary">Obrigado por entrar em contato.<br /> Retornaremos em breve!</p>
      </Modal>
    </div>
  );
};

export default App;
